// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyBSLmuP_sE3mEmddYIMmv0mfYysNXRqA9E',
    authDomain: 'psl-eventcam.firebaseapp.com',
    databaseURL: 'https://psl-eventcam.firebaseio.com',
    projectId: 'psl-eventcam',
    storageBucket: 'psl-eventcam.appspot.com',
    messagingSenderId: '215696272244',
    appId: '1:215696272244:web:17ddf0357d6d61b969da21'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
