
<h1>Events verwalten</h1>

<ul>
  <li class="text" *ngFor="let e of events | async">
    <h3>{{ e.title }}</h3>
    <a [routerLink]="['/', e.slug]">/{{ e.slug }}</a> ==> {{ e.embedType }}/{{ e.embedId }}<br />
    <a [routerLink]="['/admin', e.id]">Bearbeiten</a> | <button (click)="deleteEvent(e.id)">L&ouml;schen</button>
  </li>
</ul>
<p *ngIf="!(events | async)">
  Noch nix...
</p>

<p>
  <a [routerLink]="['/admin', 'new']">Neuer Eintrag</a>
</p>
<p>
  <a [routerLink]="['/']">Startseite</a>
</p>
