import { Component } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { auth as FirebaseAuth } from 'firebase/app';
import { Router } from '@angular/router';
import { version } from '../../package.json';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  public version: string = version;

  constructor(public auth: AngularFireAuth, private router: Router) {
  }

  login() {
    this.auth.signInWithRedirect(new FirebaseAuth.GoogleAuthProvider());
  }

  logout() {
    this.auth.signOut().then(() => this.router.navigateByUrl('/'));
  }
}
