<h1>Event bearbeiten</h1>

<form [formGroup]="eventForm" (ngSubmit)="saveEvent()">
  <div class="form-group">
    <label for="slug">URL:</label>
    <div class="input-group">
      <span>https://eventcam.li/</span>
      <input id="slug" type="text" formControlName="slug" required />
    </div>
  </div>
  <div class="form-group">
    <label for="title">Titel:</label>
    <div>
      <input id="title" type="text" formControlName="title" />
    </div>
  </div>
  <div class="form-group">
    <label for="embedId">Video:</label>
    <div class="input-group">
      <span>ID</span>
      <input id="embedId" type="text" formControlName="embedId" />
      <input type="hidden"  />
    </div>
  </div>
  <div class="form-group">
    <label for="embedId">Quelle:</label>
    <input type="radio" name="embedType" id="rb-source-vimeo" value="vimeo" formControlName="embedType" />
    <label for="rb-source-vimeo">Vimeo</label>
    <input type="radio" name="embedType" id="rb-source-youtube" value="youtube" formControlName="embedType" />
    <label for="rb-source-youtube">Youtube</label>
  </div>
  <input type="submit" value="speichern" />
</form>

<a [routerLink]="['/admin']">Zur&uuml;ck zur Liste</a>
