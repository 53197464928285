import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import { map, switchMap } from 'rxjs/operators';
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';
import { Observable } from 'rxjs';

@Component({
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditComponent implements OnInit {

  public eventForm = new FormGroup({
    slug: new FormControl('', Validators.required),
    title: new FormControl('', Validators.required),
    embedType: new FormControl('vimeo'),
    embedId: new FormControl('', Validators.required)
  });

  private id: string;

  constructor(private activatedRoute: ActivatedRoute, private router: Router, private store: AngularFirestore) { }

  ngOnInit(): void {
    this.activatedRoute.paramMap
      .pipe(
        switchMap(p => {
          this.id = p.get('id');
          return this.store.collection('events').doc<Event>(this.id).get();
        }),
        map(p => p.data() as Event))
      .subscribe(e => {
        if (e) {
          this.eventForm.patchValue(e);
        }
        else {
          this.eventForm.patchValue({ slug: '', title: '', embedType: 'vimeo', embedId: '' });
        }
      });
  }

  public saveEvent(): void {
    const collection = this.store.collection('events');
    const event = this.eventForm.value;
    if (this.id === 'new') {
      collection.add(event)
        .then(() => this.router.navigate([ 'admin' ]));
    }
    else {
      collection.doc<Event>(this.id).update(event)
        .then(() => this.router.navigate([ 'admin' ]));
    }
  }

}
