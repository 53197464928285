<div class="wrapper">
  <router-outlet></router-outlet>
  <div class="push"></div>
</div>

<footer>
  EventCam v.{{ version }} - &copy; 2020 - Pro sound & light Veranstaltungstechnik Anstalt, LI-9490 Vaduz
  <div class="auth" *ngIf="auth.user | async as user; else showLogin">
    Logged in as {{ user.displayName }}:
    <a [routerLink]="['/admin']">Admin</a>&nbsp;
    <button (click)="logout()">Logout</button>
  </div>
  <ng-template class="auth" #showLogin>
    <div class="auth">
      <button (click)="login()">Login</button>
    </div>
  </ng-template>
</footer>
