import { Component, OnInit, OnDestroy, ElementRef, AfterViewInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Subscription, BehaviorSubject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.scss']
})
export class EventComponent implements OnInit, OnDestroy, AfterViewInit {

  public event: BehaviorSubject<Event> = new BehaviorSubject<Event>(null);
  public isLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  @ViewChild('container')
  public containerElement: ElementRef;
  public videoWidth = 1000;
  public videoHeight = 563;

  private subscription: Subscription;
  private apiLoaded = false;

  constructor(
    private store: AngularFirestore,
    private activatedRoute: ActivatedRoute,
    private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe(r => {
      const slug = r.get('slug');
      if (!slug) {
        this.event.next(null);
        return;
      }
      this.isLoading.next(true);
      this.subscription = this.store.collection<Event>('events', ref => ref.where('slug', '==', slug))
        .valueChanges()
        .subscribe(e => {
          this.event.next(e ? e[0] : null);
          this.isLoading.next(false);
        });
    });
    if (!this.apiLoaded) {
      // This code loads the IFrame Player API code asynchronously, according to the instructions at
      // https://developers.google.com/youtube/iframe_api_reference#Getting_Started
      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      document.body.appendChild(tag);
      this.apiLoaded = true;
    }
  }

  ngAfterViewInit(): void {
    this.updateVideoSize();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public vimeoUrl(id: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(`https://player.vimeo.com/video/${id}`);
  }

  public youtubeUrl(id: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(`https://www.youtube.com/embed/${id}`)
  }

  public updateVideoSize(): void {
    this.videoWidth = this.containerElement.nativeElement.offsetWidth;
    this.videoHeight = this.videoWidth * 9 / 16;
  }
}
