<div #container (window:resize)="updateVideoSize()">
  <div *ngIf="!(isLoading | async); else loading">
    <div *ngIf="event | async; let event; else notFound">
      <h1>{{ event.title }}</h1>
      <div *ngIf="event.embedType === 'vimeo'" class="embed-container">
        <iframe [src]="vimeoUrl(event.embedId)" [width]="videoWidth" [height]="videoHeight" frameborder="0" webkitAllowFullScreen mozallowfullscreen allowFullScreen></iframe>
      </div>
      <div *ngIf="event.embedType === 'youtube'" class="embed-container">
        <youtube-player [videoId]="event.embedId" [width]="videoWidth" [height]="videoHeight"></youtube-player>
      </div>
    </div>
  </div>
  <ng-template #loading>
    Loading...
  </ng-template>
  <ng-template #notFound>
    URL nicht gefunden. Bitte prüfen sie die Schreibweise.
  </ng-template>
</div>
<!--
<div class="subtext">
  <p>Möchten auch Sie ihren Event live streamen? Wir helfen ihnen gerne.</p>
  <a href="https://www.psl.li" target="_blank"><img src="assets/logo_transparent.png" /></a>
</div> -->
