import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AngularFireAuthGuard } from '@angular/fire/auth-guard';
import { AdminComponent } from './admin/admin.component';
import { EventComponent } from './event/event.component';
import { HomeComponent } from './home/home.component';
import { EditComponent } from './admin/edit/edit.component';
import { map } from 'rxjs/operators';

const validUsers = [
  'info@psl.li',
  'sgacond@gmail.com'
];

const isValidUser = () => map((u: any) => validUsers.indexOf(u.email) >= 0);

const routes: Routes = [
  { path: 'admin', component: AdminComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: isValidUser } },
  { path: 'admin/:id', component: EditComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: isValidUser } },
  { path: ':slug', component: EventComponent },
  { path: '', component: HomeComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
