import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {

  events: Observable<any[]>;

  constructor(private store: AngularFirestore) { }

  ngOnInit(): void {
    this.events = this.store.collection<Event>('events').snapshotChanges()
      .pipe(map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data() as Event;
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      }));
  }

  public deleteEvent(id: string): void {
    // tslint:disable-next-line: curly
    if (!confirm('Wirklich löschen?')) return;
    this.store.collection<Event>('events').doc(id).delete();
  }

}
